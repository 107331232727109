import React, { useEffect } from "react";
import "./Footer.css";

declare global {
  interface Window {
    init?: (props: any) => void;
  }
}

const Footer: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://s.appreports.net/serenity.js?v=${new Date().getTime()}`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // This function will be called after the script has loaded
    window.init = (props: any) => {
      // Do something with props if needed
      console.log("Serenity initialized with props:", props);
    };

    // Example of how to make a Feed API call
    // Replace this with your actual Feed API call
    const fetchData = async () => {
      try {
        const response = await fetch('YOUR_FEED_API_URL');
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          if (window.init) {
            window.init(data.props);
          }
        } else {
          throw new Error('Response is not JSON');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Clean up function to remove the init function from the global scope
    return () => {
      if (window.init) {
        delete window.init;
      }
    };
  }, []);

  return (
    <footer>
      <div className="footer-links">
        <a href="/about" >About</a>
        <a href="/contact" >Contact</a>
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms</a>
      </div>
    </footer>
  );
};

export default Footer;