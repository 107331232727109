import * as React from "react";
import Footer from "../Components/Footer";
import SearchComponent from "../Components/SearchBar";

import logo4Image from "../img/fwazzy-high-resolution-logo-transparent.svg";

interface Props {}

export const Home: React.FC<Props> = () => {
  return (
    <>
      <div className="homepage-contents-container">
        <a href="/">
          <img src={logo4Image} alt="Fwazzy" />
        </a>
        <SearchComponent />
      </div>
      <Footer />
    </>
  );
};
